[data-elm-hot="true"] {
  height: inherit;
}

.editor-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.CodeMirror {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  line-height: 22px;
}

.cm-header-1 {
  font-size: 140% !important;
  font-weight: 400 !important;
  line-height: 140% !important;
}

.cm-header-2, .cm-header-3 {
  font-size: 120% !important;
  font-weight: 400 !important;
}

.iframe-container {
  padding-top: 56.25% !important;
  position: relative !important;
  overflow: hidden !important;
}

.iframe-container iframe {
  border: 0 !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.iframe-container-instagram {
  padding-top: 140% !important;
  position: relative !important;
  overflow: hidden !important;
}

.iframe-container-instagram iframe {
  border: 0 !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

#hourglass-loader {
  --duration: 3s;
  --colour: #111;
  --delay: 2s;
  animation: hourglass-loader var(--duration) var(--delay) cubic-bezier(.8, 0, .2, 1) infinite;
  width: 41px;
  height: 40px;
  position: absolute;
  top: calc(50% + 100px);
  left: calc(50% - 20px);
}

@keyframes hourglass-loader {
  90% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

#hourglass-top {
  animation: hourglass-top var(--duration) var(--delay) linear infinite, fadein calc(var(--delay)  + 1s);
  border-top: 20px solid var(--colour);
  transform-origin: 50% 100%;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  width: 1px;
  height: 0;
}

@keyframes hourglass-top {
  90% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

#hourglass-bottom {
  animation: hourglass-bottom var(--duration) var(--delay) linear infinite;
  border-right: 20px solid #0000;
  border-bottom: 20px solid var(--colour);
  transform-origin: 50% 100%;
  border-left: 20px solid #0000;
  width: 1px;
  height: 0;
  transform: scale(0);
}

@keyframes hourglass-bottom {
  10% {
    transform: scale(0);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

#hourglass-line {
  animation: hourglass-line var(--duration) var(--delay) linear infinite;
  border-left: 1px dotted var(--colour);
  width: 0;
  height: 0;
  position: absolute;
  top: 20px;
  left: 20px;
}

@keyframes hourglass-line {
  10% {
    height: 20px;
  }

  100% {
    height: 20px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Thin.df82b1a4.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-ThinItalic.187a5d12.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-ExtraLight.4eaaeff4.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-ExtraLightItalic.51e88022.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Light.a7467033.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-LightItalic.e3025b84.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Regular.e7957aca.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-RegularItalic.f68e2304.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Medium.bfad4cbf.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-MediumItalic.17fdc197.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-SemiBold.bab974ff.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-SemiBoldItalic.96cd77f0.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Bold.b9a82c59.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-BoldItalic.b2c4d7bd.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-ExtraBold.1f464602.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-ExtraBoldItalic.e126c956.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-Black.1c91f0a9.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("Metropolis-BlackItalic.59b1c3a2.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}
/*# sourceMappingURL=app.284ff1e4.css.map */
